<template>
    <div class="ExamEnd">
        <img class="end-exam-img" src="~assets/images/end_exam.png" alt="">
        <div class="hint">恭喜你完成了考试 !</div>
        <el-button class="custom-btn" type="danger" @click="exit">退出</el-button>
    </div>
</template>

<script>
export default {
    name: 'FinishExam',
    components: {},
    props: {},
    data() {
        return {
            type: localStorage.getItem("type") == 1 ? "考试" : "训练"
        }
    },
    computed: {},
    methods: {
        exit() {
            this.$router.replace("/student/competition/index");
        }
    }
}
</script>

<style scoped lang="scss">
.ExamEnd {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-top: 90px;
    .end-exam-img {
        width: 30%;
    }
    .hint {
        margin: 40px 0;
        font-size: 14px;
        color: #333;
    }
    .custom-btn {
        width: 180px;
        height: 36px;
    }
}
</style>
